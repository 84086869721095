<button
  blurAfterFocus
  class="dt-button"
  i18n="@@dt-button.text"
  i18n-matTooltip="@@dt-button.tooltip"
  matTooltip="Переход в Цифровой двойник"
  type="button"
  (click)="openDt()"
>
  ЦД
</button>
