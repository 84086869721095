import { Injectable } from '@angular/core';
import { BehaviorSubject, filter } from 'rxjs';

/**
 * Класс, представляющий сервис RouterIsLoadedService.
 * Декоратор @Injectable используется для предоставления сервиса в корневом инжекторе.
 */
@Injectable({
  providedIn: 'root',
})
export class RouterIsLoadedService {
  /**
   * Представляет состояние загрузки роутера.
   * @type {BehaviorSubject<boolean>}
   */
  readonly #routerIsLoaded$ = new BehaviorSubject(false);

  /**
   * Observable, который выдает значения из `routerIsLoaded$` после фильтрации всех ложных значений.
   *
   * @type {Observable<boolean>}
   */
  readonly routerIsLoaded$ = this.#routerIsLoaded$.pipe(filter((value) => value));

  /**
   * Устанавливает значение routerIsLoaded.
   *
   * @param {boolean} value - Новое значение для routerIsLoaded.
   *
   * @return {void}
   */
  setRouterIsLoaded(value: boolean): void {
    this.#routerIsLoaded$.next(value);
  }
}
