import { APP_INITIALIZER, Provider } from '@angular/core';
import { Observable, forkJoin, map, mergeMap, of } from 'rxjs';

import { environment } from '../../environments/environment';
import { FeatureFlagService } from '../modules/feature-flag/feature-flag.service';
import { AuthCredsService } from '../services/auth-creds.service';
import { ConfigService } from '../services/config.service';
import { DtPostmessageService } from '../services/dt-postmessage.service';
import { USER_SETTINGS_SERVICE } from '../services/user-service/user-setings.token';
import type { UserSettingsProvider } from '../services/user-service/user-settings.interface';

/**
 * Возвращает массив провайдеров, которые будут использоваться в качестве инициализаторов для приложения.
 *
 * @returns {Provider[]} Массив провайдеров для инициализаторов приложения.
 */
export function provideInitializers(): Provider[] {
  return [
    {
      provide: APP_INITIALIZER,
      useFactory:
        (
          featureFlagService: FeatureFlagService,
          configService: ConfigService,
          authCredsService: AuthCredsService,
          userSettings: UserSettingsProvider,
        ) =>
        (): Observable<boolean> => {
          return forkJoin([
            featureFlagService.isFeatureOn('CONFIG')
              ? configService.loadConfig()
              : of({
                  SIGNALING_URL: environment.SIGNALING_URL,
                  MATCHMAKER_URL: environment.MATCHMAKER_URL,
                  API_URL: environment.API_URL,
                  AUTH_TYPE: environment.AUTH_TYPE,
                  WEB_ADMIN_URL: environment.WEB_ADMIN_URL,
                  GLOB_URL: environment.GLOB_URL,
                }),
            userSettings.getSettings(),
          ]).pipe(
            mergeMap(([config]) => {
              if (!config) {
                return of(false);
              }

              return configService.getValue('AUTH_TYPE') === 'application'
                ? authCredsService.loadCredentials().pipe(map((credentials) => !!credentials))
                : of(!!config);
            }),
          );
        },
      deps: [FeatureFlagService, ConfigService, AuthCredsService, USER_SETTINGS_SERVICE],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (iframeService: DtPostmessageService) => () => iframeService.init(),
      deps: [DtPostmessageService],
      multi: true,
    },
  ];
}
