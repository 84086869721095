import { DestroyRef, Directive, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { DtPostmessageService } from '../../services/dt-postmessage.service';
import { HideInterfaceService } from '../../services/hide-interface.service';

/**
 * Представляет директиву для переключения видимости интерфейса.
 *
 * @remarks
 * Эта директива используется для скрытия или отображения интерфейса на основе полученных событий от dtPostMessageService.
 * Она слушает скрытые и отображаемые наблюдаемые объекты от dtPostMessageService и соответственно обновляет видимость интерфейса.
 */
@Directive({
  selector: '[appToggleInterfaceVisibility]',
  standalone: true,
})
export class ToggleInterfaceVisibilityDirective implements OnInit {
  /**
   * Конструктор для директивы.
   *
   * @param {DtPostmessageService} dtPostMessageService - Сервис для работы с postmessage.
   * @param {HideInterfaceService} hideInterfaceService - Сервис для скрытия интерфейсов.
   * @param {DestroyRef} destroyRef - Внедренный экземпляр DestroyRef.
   *
   * @return {void}
   */
  constructor(
    private dtPostMessageService: DtPostmessageService,
    private hideInterfaceService: HideInterfaceService,
    private destroyRef: DestroyRef,
  ) {}

  /**
   * Инициализирует компонент при его создании.
   * Этот метод автоматически вызывается Angular'ом при создании компонента.
   * Он вызывает метод "toggleInterfaceVisibilityInit" для инициализации видимости интерфейса.
   *
   * @return {void}
   */
  ngOnInit(): void {
    this.toggleInterfaceVisibilityInit();
    this.getInterfaceVisibility();
  }

  /**
   * Инициализирует функциональность переключения видимости интерфейса.
   * Подписывается на скрытые и отображаемые наблюдаемые объекты от dtPostMessageService
   * и обновляет видимость интерфейса, основываясь на полученных данных событий.
   *
   * @private
   * @returns {void}
   */
  private toggleInterfaceVisibilityInit(): void {
    this.dtPostMessageService.setInterfaceVisibility$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((eventData) => {
      if (!eventData.params || !('visibility' in eventData.params) || typeof eventData.params.visibility !== 'boolean') {
        console.error('PostMessage setInterfaceVisibility: wrong params ', eventData.params);
        return;
      }
      this.hideInterfaceService.setVisibility(eventData.params.visibility);
    });
  }

  /**
   * Получает видимость интерфейса через сервис dtPostMessageService.
   *
   * @returns {void}
   *
   * @description
   * Этот метод подписывается на observable `getInterfaceVisibility$` из `dtPostMessageService`,
   * получает текущее состояние видимости интерфейса с использованием `hideInterfaceService`, и затем
   * отправляет данные о видимости посредством post сообщения через `dtPostMessageService`.
   *
   * Примечание: Этот метод является приватным и не должен вызываться извне.
   */
  private getInterfaceVisibility(): void {
    this.dtPostMessageService.getInterfaceVisibility$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data) => {
      const currentVisibility = this.hideInterfaceService.isVisible();
      const id = data.guid;

      this.dtPostMessageService.sendPostMessage({ id, result: { visibility: currentVisibility } });
    });
  }
}
