import { PartialByKeys, RequiredByKeys } from '../../../types/partial-required-by-keys.type';
import { Tuple } from '../../../types/tuple.type';
import { IconMappingKeys } from './icons.enum';

// search result from UE
export interface BuildingSearchResult {
  name: string;
  address: string;
  buildingId: string;
  uniqId: string;
}

export const isBuildingSearchResult = (val: Omit<Doc, 'uniqId'>): val is BuildingSearchResult => {
  return (val as BuildingSearchResult).buildingId !== undefined;
};

// search result from API_URL;
export interface GisSearchResult {
  sys_documentId: string;
  stableid: string;
  name_search: string;
  address: string;
  center: string;
  bbox: string;
  inn: string;
  phones: string;
  sites: string;
  sys_type: string;
  geom: string;
  additionalinfo: string;
  address_search: string;
  objectType: IconMappingKeys;
  layer_id: string;
  objectTypePriority: number;
  _version_: number;
  name: string;
  workinghours: string;
  id: string;
  territory: string;
}

export interface Doc extends PartialByKeys<BuildingSearchResult, 'buildingId'>, RequiredByKeys<GisSearchResult, 'address' | 'name'> {
  uniqId: string;
}

export interface Point {
  type: 'Point';
  coordinates: Tuple<number, 3>;
}

export interface ShortSearchResult {
  center?: string;
  name?: string;
  address?: string;
}

export interface Places {
  numFound: number;
  pageSize: number;
  start: number;
  docs: Doc[];
}
