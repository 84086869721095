import { Injectable } from '@angular/core';
import { filter, map, merge, startWith } from 'rxjs';
import { mergeMap, share } from 'rxjs/operators';

import { RpcMessagesFrontend } from '../enums/rpc-messages.enum';
import { PixelStreamingService } from './pixelstreaming.service';

/**
 * Сервис для управления видимостью экрана с предварительной загрузкой.
 */
@Injectable({ providedIn: 'root' })
export class PreloaderService {
  /**
   * Реактивная переменная, представляющая состояние видимости экрана загрузки.
   *
   * @type {Observable<boolean>}
   */
  isVisible$ = this.pixelStreamingService.isStreamReady$.pipe(
    filter((isStreamReady) => isStreamReady),
    mergeMap(() => {
      return merge(
        this.pixelStreamingService.on(RpcMessagesFrontend.SHOW_LOADING_SCREEN).pipe(map(() => true)),
        this.pixelStreamingService.on(RpcMessagesFrontend.HIDE_LOADING_SCREEN).pipe(map(() => false)),
      );
    }),
    startWith(false),
    share(),
  );

  /**
   * Конструктор класса для PixelStreamingService.
   *
   * @param {PixelStreamingService} pixelStreamingService - The instance of PixelStreamingService.
   */
  constructor(private pixelStreamingService: PixelStreamingService) {}
}
