<header class="user-manual__header">
  <span i18n="@@user-manual.header">Руководство МЕТА</span>
  <button class="user-manual__close-btn" matDialogClose type="button">
    <svg-icon name="close" />
  </button>
</header>
<div class="container">
  <div class="user-manual__category-list">
    <mat-accordion displayMode="flat">
      @for (item of sidebarItems; track item.title) {
        <mat-expansion-panel class="mat-expansion-panel">
          <mat-expansion-panel-header>
            <mat-panel-title class="category__title">
              <svg-icon [name]="item.icon" />
              {{ item.title }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="category__item-list">
            @for (subItem of item.subItems; track $index) {
              <button mat-button type="button">
                {{ subItem }}
              </button>
            }
          </div>
        </mat-expansion-panel>
      }
    </mat-accordion>
  </div>

  <div class="user-manual__image">
    <img alt="app main screen" class="img" height="420" ngSrc="assets/images/user-manual/user-manual-img-1.webp" width="746" />
  </div>
  <div class="user-manual__instructions">
    <img alt="instructions " class="img" height="230" ngSrc="assets/images/user-manual/user-manual-img-2.webp" width="739" />
  </div>
</div>
