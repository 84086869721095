import { Pipe, PipeTransform } from '@angular/core';

import { ActivePanel } from '../toolbar.types';

/**
 * Pipe, который проверяет, активна ли кнопка меню панели инструментов.
 */
@Pipe({
  name: 'isToolbarMenuButtonActive',
  standalone: true,
})
export class IsToolbarMenuButtonActivePipe implements PipeTransform {
  /**
   * Преобразует заданные параметры routePath, activePanel, isPCGActive и isUserManualActive
   * для определения совпадают ли путь маршрута и активные панели.
   *
   * @param {string | undefined} routePath - Путь маршрута.
   * @param {ActivePanel | null} activePanel - Активная панель.
   * @param {boolean} isPCGActive - Указывает, активен ли PCG.
   * @param {boolean} isUserManualActive - Указывает, активен ли элемент пользовательское руководство.
   *
   * @return {boolean} - Возвращает true, если путь маршрута и активные панели совпадают;
   *                    в противном случае, возвращает false.
   */
  transform(routePath: string | undefined, activePanel: ActivePanel | null, isPCGActive: boolean, isUserManualActive: boolean): boolean {
    return (
      activePanel === routePath ||
      (routePath === 'pcg' && isPCGActive) ||
      (routePath === 'pcg_old' && isPCGActive) ||
      (routePath === 'user-manual' && isUserManualActive)
    );
  }
}
