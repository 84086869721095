import { Routes } from '@angular/router';

import { featureFlagGuard } from '../../guards/feature-flag.guard';
import { RouteData } from '../../types/route-data.interface';
import { ToolbarComponent } from './toolbar.component';

export const TOOLBAR_ROUTES: Routes = [
  {
    path: '',
    component: ToolbarComponent,
    children: [
      {
        canActivate: [featureFlagGuard],
        path: 'search',
        data: { title: $localize`Поиск`, hiddenTitle: true, featureFlagKey: 'SEARCH' } as RouteData,
        loadComponent: () => import('./search/search.component').then((m) => m.SearchComponent),
      },
      {
        canActivate: [featureFlagGuard],
        path: 'stratums',
        data: { title: $localize`Слои`, featureFlagKey: 'STRATUMS', noBorder: true, overflow: 'hidden' } as RouteData,
        loadComponent: () => import('./stratums/stratums.component').then((m) => m.StratumsComponent),
      },
      {
        canActivate: [featureFlagGuard],
        path: 'levels',
        data: { title: $localize`Локации`, featureFlagKey: 'LEVELS' } as RouteData,
        loadComponent: () => import('./levels/levels.component').then((m) => m.LevelsComponent),
      },
      {
        canActivate: [featureFlagGuard],
        path: 'layers',
        data: { title: $localize`Подложки`, featureFlagKey: 'LAYERS' } as RouteData,
        loadComponent: () => import('./layers/layers.component').then((m) => m.LayersComponent),
      },
      {
        canActivate: [featureFlagGuard],
        path: 'favorites',
        data: { title: $localize`Избранное`, featureFlagKey: 'FAVORITES', noBorder: true, icon: 'favorites' } as RouteData,
        loadComponent: () => import('./favorites/favorites.component').then((m) => m.FavoritesComponent),
      },
      {
        canActivate: [featureFlagGuard],
        path: '3d-models',
        data: { title: $localize`Трёхмерные модели`, featureFlagKey: '3D-MODELS', noBorder: true, icon: '3DModels' } as RouteData,
        loadComponent: () => import('./3dmodels/models3d.component').then((m) => m.Models3dComponent),
      },
      {
        canActivate: [featureFlagGuard],
        path: 'pcg',
        data: { title: $localize`Генеративное проектирование`, featureFlagKey: 'PCG', noBorder: true, icon: 'pcg' } as RouteData,
        loadComponent: () => import('./generative-toolbar/generative-toolbar.component').then((m) => m.GenerativeToolbarComponent),
      },
      {
        canActivate: [featureFlagGuard],
        path: 'pcg_old',
        data: { title: $localize`Генеративное проектирование`, featureFlagKey: 'PCG_OLD', noBorder: true, icon: 'pcg' } as RouteData,
        redirectTo: '',
      },
      {
        canActivate: [featureFlagGuard],
        path: 'settings',
        data: { title: $localize`Настройки`, featureFlagKey: 'SETTINGS', noBorder: true } as RouteData,
        loadComponent: () => import('./settings/settings.component').then((m) => m.SettingsComponent),
      },
      {
        canActivate: [featureFlagGuard],
        path: 'profile',
        data: { title: $localize`Профиль`, featureFlagKey: 'PROFILE', noBorder: true } as RouteData,
        loadComponent: () => import('./profile/profile.component').then((m) => m.ProfileComponent),
        children: [
          {
            canActivate: [featureFlagGuard],
            path: 'settings',
            data: { title: $localize`Настройки`, featureFlagKey: 'SETTINGS', noBorder: true } as RouteData,
            loadComponent: () => import('./settings/settings.component').then((m) => m.SettingsComponent),
          },
        ],
      },
      {
        canActivate: [featureFlagGuard],
        path: 'user-manual',
        data: { title: $localize`Руководство МЕТА`, featureFlagKey: 'USER-MANUAL', noBorder: true, icon: 'userManual' } as RouteData,
        redirectTo: '',
      },
    ],
  },
];
