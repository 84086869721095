import { IconType } from '../../modules/svg-icon/generated-svg/svg-icons';

export interface ObjectInfoAPI {
  title?: string;
  icon?: IconType;
}

export interface ObjectInfo extends ObjectInfoAPI {
  type: 'FeatureCollection';
  buildingId: string;
  useOldApiBuildingOption: boolean;
  features: {
    type: 'ObjectFeature';
    hasDemoViewMode?: boolean;
    hasBuildingsOptionIcon?: boolean;
    geometry: {
      type: 'Point';
      coordinates: [number, number, number?];
    };
    properties: {
      FNO_code: { value: string; isEnabled: boolean };
      FNO_name: { value: string; isEnabled: boolean };
      ZU_area: { value: number; isEnabled: boolean };
      act_AGR: { value: string; isEnabled: boolean };
      address: { value: string; isEnabled: boolean };
      cadNum: { value: string; isEnabled: boolean };
      designer: { value: string; isEnabled: boolean };
      developer: { value: string; isEnabled: boolean };
      h_abs: { value: number; isEnabled: boolean };
      h_otn: { value: number; isEnabled: boolean };
      h_relief: { value: number; isEnabled: boolean };
      name: { value: string; isEnabled: boolean };
      okrug: { value: string; isEnabled: boolean };
      rajon: { value: string; isEnabled: boolean };
      s_naz: { value: number; isEnabled: boolean };
      s_obsh: { value: number; isEnabled: boolean };
      s_podz: { value: number; isEnabled: boolean };
      spp_gns: { value: number; isEnabled: boolean };
    };
  };
}

export interface PropertyGroup {
  type: 'developer' | 'general' | 'height' | 'square';
  text: string;
}

export interface Property {
  text: string;
  icon: IconType;
  code: keyof ObjectInfo['features']['properties'] | 'coordinates';
  value: number | string;
  group: PropertyGroup;
}

export const PropertyGroup: Record<'DEVELOPER' | 'GENERAL' | 'HEIGHT' | 'SQUARE', PropertyGroup> = {
  GENERAL: { type: 'general', text: $localize`Общая информация` },
  DEVELOPER: { type: 'developer', text: $localize`Застройщик` },
  SQUARE: { type: 'square', text: $localize`Площадь участка` },
  HEIGHT: { type: 'height', text: $localize`Высота` },
};

export const PropertyGroupKeysOrder = ['general', 'developer', 'square', 'height'] as const;

export type GroupedProperties = Record<
  'developer' | 'general' | 'height' | 'square',
  { type: 'developer' | 'general' | 'height' | 'square'; collapsed: boolean; values: Property[] }
>;

export enum View {
  MINIMUM = 0,
  STANDARD = 1,
  REVEALED = 2,
}

export interface MenuItem {
  uuid: string;
  text: string;
  isActive: boolean;
  type: View;
}

export interface MenuGroup {
  uuid: string;
  title: string;
  items: MenuItem[];
}

export const OBJECT_CARD_VIEW_TYPE = 'object-card.view.type' as const;
