import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, of, switchMap, take } from 'rxjs';

import { GLOBE_URL_DEV } from '../constants/app.constant';
import { FeatureFlagService } from '../modules/feature-flag/feature-flag.service';
import { DataHubDictionary } from '../types/external.interface';
import { BackendEventsService } from './backend-events.service';
import { ConfigService } from './config.service';

/**
 * Класс ExternalService
 * @description Сервис для преобразования координат Meta в координаты DT
 */
@Injectable({ providedIn: 'root' })
export class ExternalService {
  /**
   * Конструктор для инициализации класса.
   *
   * @param {BackendEventsService} backendEventsService - Сервис обработки событий внутри системы.
   * @param {ConfigService} configService - сервис для управления конфигурацией приложения
   * @param {HttpClient} httpClient - HTTP-клиент для выполнения HTTP-запросов.
   * @param {FeatureFlagService} featureFlagService - Сервис флагов функций, используемый для проверки статуса флагов функций.
   *
   */
  constructor(
    private backendEventsService: BackendEventsService,
    private configService: ConfigService,
    private httpClient: HttpClient,
    private featureFlagService: FeatureFlagService,
  ) {}

  /**
   * Инициализирует установку URL ссылки для глобуса в UE.
   *
   * @private
   * @returns {void}
   */
  initGlobeUrl(): void {
    const globeUrl = this.getGlobeAPIUrl();

    this.httpClient
      .get<DataHubDictionary>(globeUrl)
      .pipe(
        map((resp) => {
          return this.getGlobeUrlFromResponse(resp);
        }),
        catchError(() => {
          return of('');
        }),
        switchMap((globeUrl: string) => {
          return this.backendEventsService.setGlobeUrl(globeUrl);
        }),
      )
      .pipe(take(1))
      .subscribe();
  }

  /**
   * Возвращает актуальный URL ссылки на API для запроса.
   *
   * @returns {string} - URL ссылки на API для запроса.
   */
  getGlobeAPIUrl(): string {
    return this.featureFlagService.isFeatureOn('CONFIG') ? this.configService.getValue('GLOB_URL') : GLOBE_URL_DEV;
  }

  /**
   * Возвращает URL ссылки на глобус из запроса.
   *
   * @param {DataHubDictionary} resp - Объект ответа от API.
   * @returns {string} - URL ссылки на глобус.
   */
  getGlobeUrlFromResponse(resp: DataHubDictionary): string {
    return resp?.elements[0]?.values[0] ?? '';
  }
}
