import { Pipe, PipeTransform } from '@angular/core';

/**
 * Класс IncludesPipe, представляющий кастомный пайп для проверки наличия элемента в массиве.
 */
@Pipe({ name: 'includes', standalone: true })
export class IncludesPipe implements PipeTransform {
  /**
   * Функция transform, выполняющая проверку наличия элемента в массиве.
   * @param arr Массив элементов типа number или string.
   * @param id Элемент для проверки наличия в массиве.
   * @returns Возвращает true, если элемент присутствует в массиве, иначе false.
   */
  transform(arr: (number | string)[] = [], id?: number | string): boolean {
    if (!id) {
      return true;
    }

    return arr.includes(id);
  }
}
