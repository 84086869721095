import { Injectable, inject } from '@angular/core';

import { environment } from '../../environments/environment';
import { PixelStreamingService } from './pixelstreaming.service';

/**
 * Класс MetaToDtService
 * @description Сервис для преобразования координат Meta в координаты DT
 */
@Injectable({ providedIn: 'root' })
export class MetaToDtService {
  /**
   * Базовый URL-адрес для 3D-карты.
   *
   * @type {string}
   * @constant
   */
  #dTBaseMapUrl = '/map3d/#/map3d' as const;

  /**
   * Представляет экземпляр PixelStreamingService.
   *
   * @class
   * @name PixelStreamingService
   *
   * @param {PixelStreamingService} pixelStreamingService - Экземпляр PixelStreamingService, который внедрен.
   */
  #pixelStreamingService = inject(PixelStreamingService);

  /**
   * Преобразует координаты Meta в координаты DT и возвращает результат в виде строки.
   *
   * @param {string} coords - Координаты Meta для конвертации.
   * @throws {Error} Генерирует ошибку, если заданные координаты недействительны.
   * @returns {string} Координаты DT в виде строки.
   */
  metaToDtCoords(coords: string): string {
    if (!coords || !this.#pixelStreamingService.validateTeleportCamera(coords)) {
      throw new Error('Wrong Meta coordinates to convert to DT');
    }

    const [x, y, z, yaw, pitch] = coords.split(',').map(parseFloat);
    const dtYaw = this.metaYawToDt(yaw);
    return `${environment.DT_URL}${this.#dTBaseMapUrl}#coords${[x, y, z, dtYaw, pitch].join(',')}`;
  }

  /**
   * Получает базовый URL для функциональности DT (Data Table).
   *
   * @returns {string} Базовый URL DT
   */
  getBaseDtUrl(): string {
    return `${environment.DT_URL}${this.#dTBaseMapUrl}`;
  }

  /**
   * Преобразует значение metaYaw в градусы.
   *
   * @param {number} yaw - Значение metaYaw в градусах.
   * @returns {number} - Преобразованное значение metaYaw в градусах.
   */
  private metaYawToDt(yaw: number): number {
    return (yaw + 90) % 360;
  }
}
