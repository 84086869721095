import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

/**
 * Сервис для управления основным HTML прелоадером.
 *
 * @remarks
 * Этот сервис предоставляет функциональность для скрытия основного элемента HTML прелоадера.
 *
 * @param document - Объект документа, с которым работает.
 * @param featureFlagService - Сервис для управления функциональными флагами.
 */
@Injectable({ providedIn: 'root' })
export class MainHtmlPreloaderService {
  /**
   * Число попыток соединения с сервером MatchMaker.
   *
   * @type {number}
   */
  #countMatchMakerServerTryConnection = 0;

  /**
   * Создает экземпляр конструктора.
   *
   * @param {Document} document - Ссылка на объект документа.
   */
  constructor(@Inject(DOCUMENT) private document: Document) {}

  /**
   * Скрывает элемент предзагрузчика.
   *
   * @returns {void}
   */
  hidePreloader(): void {
    this.document.getElementById('preloader')?.classList.add('preloader_hide');
  }

  /**
   * Измените подзаголовок для поискового сервера.
   *
   * Этот метод обновляет элементы подзаголовка поискового сервера на основе количества попыток подключения.
   * Если количество попыток подключения равно 3 или более, то класс 'sub-title_visible' добавляется к элементу 'sub-title-connect',
   * класс 'sub-title_visible' удаляется из элемента 'no-error-sub-title', и внутренний текст элемента 'sub-title-connect'
   * обновляется счетчиком попыток подключения.
   *
   * Если количество попыток подключения равно 23 или более, то класс 'text_visible' добавляется к элементу 'preloader-error-refresh',
   * внутренний текст элемента 'no-error-title' обновляется ошибкой, и атрибут transform родительского элемента
   * 'no-error-title' обновляется.
   *
   * @returns {void}
   */
  changeSubTitleForSearchServer(): void {
    const subTitle = this.document.getElementById('no-error-sub-title');
    const subTitleConnect = this.document.getElementById('no-error-sub-title-connect');

    if (subTitle && subTitleConnect) {
      this.#countMatchMakerServerTryConnection = this.#countMatchMakerServerTryConnection + 1;

      if (this.#countMatchMakerServerTryConnection >= 3) {
        subTitle.classList.remove('sub-title_visible');
        subTitleConnect.classList.add('sub-title_visible');
        subTitleConnect.textContent = $localize`:@@main-preloader.subtitle-count:Осуществляется попытка подключения к серверу, пожалуйста ожидайте. Осуществлено попыток ${this.#countMatchMakerServerTryConnection - 2}`;
      }

      if (this.#countMatchMakerServerTryConnection >= 23) {
        this.document.getElementById('preloader-error-refresh')?.classList.add('text_visible');

        const noErrorTitle = this.document.getElementById('no-error-title');

        if (noErrorTitle) {
          noErrorTitle.textContent = $localize`:@@main-preloader.no-error.title:Подключение не удалось`;
          noErrorTitle.parentElement?.setAttribute('transform', 'translate(805 420.835266)');
        }
      }
    }
  }

  /**
   * Изменяет подзаголовок подключения к серверу для данного документа.
   * Поиск элемента с идентификатором "no-error-sub-title" и установка его текстового содержимого
   * на переведенную версию строки "@@main-preloader.no-error.subtitle".
   *
   * @returns {void} Не возвращает значение.
   */
  changeSubTitle2ServerConnection(): void {
    const subTitle = this.document.querySelector('#no-error-sub-title tspan');

    if (subTitle) {
      subTitle.textContent = $localize`:@@main-preloader.no-error.subtitle:Подключение к серверу...`;
    }
  }

  /**
   * Показать журналы подключения на основе предоставленного eventType.
   * @param {string} eventType - Тип события для отображения логов.
   *        Валидные значения: 'dataChannelOpen', 'initialSettings', 'playerCount',
   *        'PlayPromiseSuccess', 'playStream', 'videoInitialized', 'webRtcConnected',
   *        'webRtcConnecting', 'webRtcSdp'.
   * @return {void}
   */
  showConnectionLogs(
    eventType:
      | 'dataChannelOpen'
      | 'initialSettings'
      | 'playerCount'
      | 'PlayPromiseSuccess'
      | 'playStream'
      | 'videoInitialized'
      | 'webRtcConnected'
      | 'webRtcConnecting'
      | 'webRtcSdp',
  ): void {
    if (eventType === 'playerCount') {
      this.document.getElementById('connection-logs')?.classList.add('text_visible');
    } else {
      let index = 0;

      switch (eventType) {
        case 'webRtcConnecting':
          index = 1;
          break;
        case 'webRtcSdp':
          index = 2;
          break;
        case 'webRtcConnected':
          index = 3;
          break;
        case 'dataChannelOpen':
          index = 4;
          break;
        case 'initialSettings':
          index = 5;
          break;
        case 'videoInitialized':
          index = 6;
          break;
        case 'playStream':
          index = 6;
          break;
        case 'PlayPromiseSuccess':
          index = 7;
          break;
      }

      const connectionLogs = this.document.getElementById('connection-logs');
      const visibleEl = connectionLogs?.querySelector('tspan.visible');

      const logsNodes = connectionLogs ? Array.from(connectionLogs.children) : [];

      if (visibleEl) {
        const visibleElIndex = logsNodes.indexOf(visibleEl);

        if (visibleElIndex >= index) {
          return;
        }

        connectionLogs?.setAttribute('transform', `translate(643.119873 ${274.056705 - index * 32})`);
        this.document.querySelector('.prev-visible')?.classList.remove('prev-visible');
        connectionLogs?.querySelectorAll('tspan')?.[index]?.classList.add('visible');
        visibleEl.classList.remove('visible');
        visibleEl.classList.add('prev-visible');
      }
    }
  }

  /**
   * Устанавливает видимость определенных элементов в зависимости от полученного статуса.
   *
   * @param {boolean} status - Статус, указывающий, доступен ли сервер matchmaker или нет.
   *                           Если `true`, некоторые элементы будут отображаться;
   *                           если `false`, некоторые элементы будут скрыты.
   *
   * @returns {void}
   */
  matchmakerIsUnavailable(status: boolean): void {
    if (status) {
      this.document.getElementById('preloader-no-error')?.classList.remove('text_visible');
      this.document.getElementById('preloader-error')?.classList.add('text_visible');
      this.document.querySelector('.no-signalling-servers__backdrop')?.classList.add('no-signalling-servers__backdrop_visible');
    } else {
      this.document.getElementById('preloader-no-error')?.classList.add('text_visible');
      this.document.getElementById('preloader-error')?.classList.remove('text_visible');
      this.document.querySelector('.no-signalling-servers__backdrop')?.classList.remove('no-signalling-servers__backdrop_visible');
    }
  }
}
