import { InjectionToken, inject } from '@angular/core';

import { FeatureFlagService } from '../../modules/feature-flag/feature-flag.service';
import { UserSettingsApiService } from './user-settings-api.service';
import { UserSettingsLocalstorageService } from './user-settings-localstorage.service';
import { UserSettingsProvider } from './user-settings.interface';

export const USER_SETTINGS_SERVICE = new InjectionToken<UserSettingsProvider>('Token to provide user settings serviceo logic', {
  factory: (): UserSettingsProvider =>
    inject(FeatureFlagService).isFeatureOn('USER_SETTINGS_API') ? inject(UserSettingsApiService) : inject(UserSettingsLocalstorageService),
});
