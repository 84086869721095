import { Injectable, signal } from '@angular/core';

/**
 * Класс сервиса HideInterfaceService предоставляет функциональность для скрытия и отображения интерфейса.
 */
@Injectable({
  providedIn: 'root',
})
export class HideInterfaceService {
  /**
   * Представляет текущее состояние видимости элемента.
   *
   * @typedef {Object} isVisible
   * @property {boolean} value - Текущее состояние видимости элемента.
   * @property {function(boolean): void} set - Функция для обновления состояния видимости.
   */
  isVisible = signal(true);

  /**
   * Устанавливает видимость элемента.
   *
   * @param {boolean} value - Значение для установки видимости элемента.
   * @return {void}
   */
  setVisibility(value: boolean): void {
    this.isVisible.set(value);
  }
}
