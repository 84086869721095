import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule, IMAGE_CONFIG, NgOptimizedImage } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, RouterOutlet } from '@angular/router';
import { LetDirective, PushPipe } from '@ngrx/component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { APP_ROUTES } from './app.routes';
import { AuthComponent } from './components/auth/auth.component';
import { CenterMapComponent } from './components/center-map/center-map.component';
import { ControlModesComponent } from './components/control-modes/control-modes.component';
import { DtButtonComponent } from './components/dt-button/dt-button.component';
import { IntroComponent } from './components/intro/intro.component';
import { LightToggleButtonComponent } from './components/light-toggle-button/light-toggle-button.component';
import { LoadingComponent } from './components/loading/loading.component';
import { ObjectCardComponent } from './components/object-card/object-card.component';
import { SnapshotComponent } from './components/snapshot/snapshot.component';
import { BlurAfterFocusDirective } from './directives/blur-after-focus/blur-after-focus.directive';
import { HideInterfaceDirective } from './directives/hide-interface/hide-interface.directive';
import { provideInitializers } from './initializers/provide-initializers';
import { BaseUrlInterceptor } from './interceptors/base-url.interceptor';
import { FeatureFlagDirective } from './modules/feature-flag/feature-flag.directive';
import { default as icons } from './modules/svg-icon/generated-svg/svg-icons';
import { SvgIconModule } from './modules/svg-icon/svg-icon.module';
import { SVG_REGISTRY_TOKEN } from './modules/svg-icon/svg-icon.token';
import { FavoritesEffects } from './modules/toolbar/favorites/store/favorites.effects';
import { favoritesReducer } from './modules/toolbar/favorites/store/favorites.reducer';
import { LayersEffects } from './modules/toolbar/layers/store/layers.effects';
import { layersReducer } from './modules/toolbar/layers/store/layers.reducer';
import { LevelsEffects } from './modules/toolbar/levels/store/levels.effects';
import { levelsReducer } from './modules/toolbar/levels/store/levels.reducer';
import { ToolbarComponent } from './modules/toolbar/toolbar.component';
import { UserManualComponent } from './modules/user-manual/component/user-manual.component';
import { ConvertTypePipe } from './pipe/convert-type/convert-type.pipe';
import { PrefixBase64Pipe } from './pipe/prefix-base64/prefix-base64.pipe';
import { AuthCredsService } from './services/auth-creds.service';
import { ConfigService } from './services/config.service';

@NgModule({
  declarations: [AppComponent, ObjectCardComponent],
  bootstrap: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    RouterOutlet,
    SvgIconModule,
    MatButtonModule,
    IntroComponent,
    ToolbarComponent,
    AuthComponent,
    ConvertTypePipe,
    PrefixBase64Pipe,
    FeatureFlagDirective,
    LoadingComponent,
    ClipboardModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(
      { layers: layersReducer, levels: levelsReducer, favorites: favoritesReducer },
      {
        metaReducers: [],
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictActionTypeUniqueness: true,
        },
      },
    ),
    EffectsModule.forRoot(LayersEffects, LevelsEffects, FavoritesEffects),
    environment.production ? [] : StoreDevtoolsModule.instrument({ maxAge: 300, connectInZone: true }),
    RouterModule.forRoot(APP_ROUTES),
    LetDirective,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    PushPipe,
    LightToggleButtonComponent,
    ControlModesComponent,
    BlurAfterFocusDirective,
    NgOptimizedImage,
    UserManualComponent,
    SnapshotComponent,
    DtButtonComponent,
    HideInterfaceDirective,
    CenterMapComponent,
  ],
  providers: [
    ConfigService,
    AuthCredsService,
    ...provideInitializers(),
    { provide: SVG_REGISTRY_TOKEN, useValue: icons },
    { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
    { provide: IMAGE_CONFIG, useValue: { placeholderResolution: 40 } },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
