import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { ActivatedRoute } from '@angular/router';

import { BlurAfterFocusDirective } from '../../directives/blur-after-focus/blur-after-focus.directive';
import { SvgIconModule } from '../../modules/svg-icon/svg-icon.module';
import { MetaToDtService } from '../../services/meta-to-dt.service';
import { WINDOW } from '../../tokens/window.token';

/**
 * Класс DtButtonComponent представляет кнопочный компонент, используемый для открытия Dt (Deep Trace) ссылки.
 * Кнопка стилизуется с помощью стилей Sass и имеет всплывающую подсказку.
 *
 * Этот класс является компонентом и может использоваться как автономный компонент при установке свойства `standalone` в значение `true`.
 * Он импортирует `SvgIconModule`, `BlurAfterFocusDirective` и `MatTooltip`.
 * Шаблон компонента загружается из './dt-button.component.html', а стили из './dt-button.component.scss'.
 * Компонент использует стратегию обнаружения изменений `OnPush`.
 *
 * @example
 * HTML:
 * <app-dt-button></app-dt-button>
 *
 * TypeScript:
 * import { DtButtonComponent } from './dt-button.component';
 *
 * @Component({
 *   selector: 'app-dt-button',
 *   standalone: true,
 *   imports: [SvgIconModule, BlurAfterFocusDirective, MatTooltip],
 *   templateUrl: './dt-button.component.html',
 *   styleUrl: './dt-button.component.scss',
 *   changeDetection: ChangeDetectionStrategy.OnPush,
 * })
 * export class DtButtonComponent {
 *   constructor(
 *     @Inject(WINDOW) private window: Window,
 *     private dtService: MetaToDtService,
 *     private activatedRoute: ActivatedRoute,
 *   ) {}
 *
 *   openDt(): void {
 *     const { coords } = this.activatedRoute.snapshot.queryParams;
 *
 *     const dtLink = coords ? this.dtService.metaToDtCoords(coords) : this.dtService.getBaseDtUrl();
 *
 *     dtLink && this.window.open(dtLink);
 *   }
 * }
 */
@Component({
  selector: 'app-dt-button',
  standalone: true,
  imports: [SvgIconModule, BlurAfterFocusDirective, MatTooltip],
  templateUrl: './dt-button.component.html',
  styleUrl: './dt-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DtButtonComponent {
  /**
   * Конструктор для класса.
   *
   * @param {Window} window - Объект window.
   * @param {MetaToDtService} dtService - Объект MetaToDtService.
   * @param {ActivatedRoute} activatedRoute - Объект ActivatedRoute.
   */
  constructor(
    @Inject(WINDOW) private window: Window,
    private dtService: MetaToDtService,
    private activatedRoute: ActivatedRoute,
  ) {}

  /**
   * Открывает ссылку DT на основе координат из queryParams или базового URL DT.
   *
   * @returns {void}
   */
  openDt(): void {
    const { coords } = this.activatedRoute.snapshot.queryParams;

    const dtLink = coords ? this.dtService.metaToDtCoords(coords) : this.dtService.getBaseDtUrl();

    dtLink && this.window.open(dtLink);
  }
}
