import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

import { ThemeService } from '../../services/theme.service';
import { SnapshotService } from './snapshot.service';

/**
 * Компонент для отображения снимка экрана
 *
 * @param {string} selector - селектор компонента
 * @param {boolean} standalone - флаг, указывающий на то, что компонент является автономным
 * @param {Array} imports - массив импортов
 * @param {string} templateUrl - путь к шаблону компонента
 * @param {string} styleUrl - путь к файлу стилей компонента
 * @param {ChangeDetectionStrategy} changeDetection - стратегия обнаружения изменений
 */
@Component({
  selector: 'app-snapshot',
  standalone: true,
  imports: [],
  templateUrl: './snapshot.component.html',
  styleUrl: './snapshot.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnapshotComponent {
  /**
   * Флаг видимости компонента
   *
   * @returns {boolean} - true, если компонент видим, иначе false
   */
  @HostBinding('class.visible')
  get isVisible(): boolean {
    return !!this.snapshot();
  }

  /**
   * Функция для создания снимка экрана
   */
  snapshot = this.snapshotService.snapshot;

  /**
   * Получает текущую тему из сервиса тем
   * @type {string}
   */
  theme = this.themeService.theme;

  /**
   * Конструктор класса.
   *
   * @param {SnapshotService} snapshotService Сервис снимков.
   * @param {ThemeService} themeService Сервис тем.
   */
  constructor(
    private snapshotService: SnapshotService,
    private themeService: ThemeService,
  ) {}
}
