<button
  blurAfterFocus
  class="light-button"
  matTooltipPosition="below"
  type="button"
  [matTooltip]="lightType() === light.NIGHT_LIGHT ? LightButtonTooltipText.DAY_ON : LightButtonTooltipText.NIGHT_ON"
  (click)="lightToggle()"
  (mouseenter)="setIcons('filled')"
  (mouseleave)="setIcons('non-filled')"
>
  <div class="track" [class.is-night-mode]="lightType() === light.NIGHT_LIGHT">
    <svg-icon class="light-button__icon light-button__icon_offset-left" [name]="nightIconName" />
    <svg-icon class="light-button__icon" [name]="dayIconName" />
  </div>
</button>
