import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import sha256 from 'sha256';

import { AUTH_HIDE_PRELOADER_TIME } from '../../constants/app.constant';
import { AuthCredsService } from '../../services/auth-creds.service';
import { ConfigService } from '../../services/config.service';
import { MainHtmlPreloaderService } from '../../services/main-html-preloader.service';
import { WINDOW } from '../../tokens/window.token';
import { AuthForm } from './auth.interface';

/**
 * Компонент для аутентификации пользователя.
 */
@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  imports: [CommonModule, ReactiveFormsModule],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthComponent implements OnInit {
  /**
   * Излучатель событий используется для сигнализации об изменении состоянии входа в систему.
   *
   * @typedef {import('events').EventEmitter<boolean>} loggedInChange
   * @see {@link https://nodejs.org/api/events.html#events_class_eventemitter | EventEmitter}
   */
  @Output() loggedInChange = new EventEmitter<boolean>();
  /**
   * Представляет сообщение.
   *
   * @typedef {string} Message
   */
  message = '';
  /**
   * Представляет форму аутентификации.
   *
   * @typedef {Object} AuthForm
   * @property {FormControl} username - Контроль для поля имени пользователя.
   * @property {FormControl} password - Контроль для поля пароля.
   */
  form = this.fb.group<AuthForm>({
    username: this.fb.control('', { validators: [Validators.required], nonNullable: true }),
    password: this.fb.control('', { validators: [Validators.required], nonNullable: true }),
  });

  /**
   * Создает новый экземпляр ClassName.
   *
   * @param {Window} window - Внедряемый объект window.
   * @param {FormBuilder} fb - Объект построителя формы.
   * @param {MainHtmlPreloaderService} mainHtmlPreloaderService - Объект сервиса preloader сайта.
   * @param {AuthCredsService} authCredsService - сервис для управления аутентификационными учетными данными
   * @param {ConfigService} configService - сервис для управления конфигурацией приложения
   */
  constructor(
    @Inject(WINDOW) private window: Window,
    private fb: FormBuilder,
    private mainHtmlPreloaderService: MainHtmlPreloaderService,
    private authCredsService: AuthCredsService,
    private configService: ConfigService,
  ) {}

  /**
   * Выполняется при инициализации компонента.
   *
   * Проверяет тип аутентификации и выполняет необходимые действия на основе типа.
   *
   * @return {void}
   */
  ngOnInit(): void {
    if (this.configService.getValue('AUTH_TYPE') === 'application') {
      this.checkLocalCredentials();
    } else {
      this.emit(true);
    }
  }

  /**
   * Вход пользователя в систему.
   *
   * @returns {void}
   */
  login(): void {
    this.message = '';
    const { credentials } = this.authCredsService;
    const { username = '', password = '' } = this.form.value;
    if (username === credentials?.username && sha256(password) === credentials?.password) {
      this.saveLocalCredentials(username, password);
      this.emit(true);
    } else {
      this.emit(false);
      this.message = 'Неверное имя пользователя или пароль';
    }
  }

  /**
   * Сохраняет заданные имя пользователя и пароль в сессионном хранилище браузера.
   *
   * @param {string} username - Имя пользователя для сохранения.
   * @param {string} password - Пароль для сохранения.
   * @return {void}
   */
  private saveLocalCredentials(username: string, password: string): void {
    this.window.localStorage.setItem('username', username);
    this.window.localStorage.setItem('password', password);
  }

  /**
   * Этот метод проверяет, есть ли сохраненные локальные учетные данные в сессионном хранилище.
   * Если обнаружены и имя пользователя, и пароль, он обновляет форму учетными данными
   * и вызывает метод "login". В противном случае он скрывает прелоадер.
   *
   * @return {void}
   */
  private checkLocalCredentials(): void {
    const username = this.window.localStorage.getItem('username');
    const password = this.window.localStorage.getItem('password');

    if (username && password) {
      this.form.patchValue({ username, password });
      this.login();
    } else {
      this.hidePreloader();
    }
  }

  /**
   * Излучает булевое значение и запускает событие loggedInChange.
   *
   * @param {boolean} value - Булевое значение для излучения.
   * @returns {void}
   */
  private emit(value: boolean): void {
    if (!value) {
      this.hidePreloader();
    }

    this.loggedInChange.emit(value);
  }

  /**
   * Скрывает элемент прелоадера.
   *
   * @private
   * @returns {void}
   */
  private hidePreloader(): void {
    setTimeout(() => this.mainHtmlPreloaderService.hidePreloader(), AUTH_HIDE_PRELOADER_TIME);
  }
}
