import { Injectable, inject, signal } from '@angular/core';
import { take } from 'rxjs';

import { USER_SETTINGS_SERVICE } from './user-service/user-setings.token';

/**
 * Представляет тему для приложения.
 * @typedef {('dark' | 'light')} Theme
 */
export type Theme = 'dark' | 'light';

/**
 * Сервис, ответственный за управление темой приложения.
 */
@Injectable({ providedIn: 'root' })
export class ThemeService {
  /**
   * Получает настройки пользователя из сервиса настроек пользователя.
   * @param {USER_SETTINGS_SERVICE} userSettings - Сервис настроек пользователя, из которого нужно получить настройки.
   */
  #userSettings = inject(USER_SETTINGS_SERVICE);

  /**
   * Представляет переменную с именем "theme", которая представляет сигнал с начальным значением типа "Theme".
   *
   * @type {Signal<Theme>}
   */
  theme = signal<Theme>(this.#userSettings.settings().theme ?? 'dark');

  /**
   * Устанавливает тему материала.
   *
   * @param {Theme} theme - Выбранная тема.
   * @param {boolean} [patchStorage=true] - Флаг для обновления хранилища.
   * @returns {void}
   */
  setMaterialTheme(theme: Theme, patchStorage = true): void {
    this.theme.set(theme);
    document.body.classList.remove(theme === 'light' ? 'dark-theme' : 'light-theme');
    document.body.classList.add(theme + '-theme');

    if (patchStorage) {
      this.#userSettings.patchSettings({ theme }).pipe(take(1)).subscribe();
    }
  }

  /**
   * Переключает тему между светлой и темной.
   *
   * @return {void} Нет возвращаемого значения.
   */
  toggleTheme(): void {
    this.setMaterialTheme(this.theme() === 'light' ? 'dark' : 'light');
  }

  /**
   * Устанавливает начальное значение темы.
   * @returns {void}
   */
  setThemeInitialValue(): void {
    this.setMaterialTheme(this.theme(), false);
  }
}
