import { Injectable, inject, signal } from '@angular/core';
import { Subject } from 'rxjs';

import { WINDOW } from '../../tokens/window.token';

/**
 * ResizeService предоставляет функционал для управления событиями изменения размера окна
 * и хранения ширины панели в локальном хранилище.
 */
@Injectable({ providedIn: 'root' })
export class ResizeService {
  /**
   * Представляет переменную `window`, которая внедряется с зависимостью `WINDOW`.
   *
   * @global
   * @type {Object}
   * @readonly
   */
  readonly #window = inject(WINDOW);

  /**
   * Subject, который генерирует события, связанные с изменением размера окна, предоставляя новую ширину и идентификатор имени.
   *
   * Этот Observable можно использовать для подписки на события изменения размера и их обработки в приложении.
   * Каждое переданное значение содержит объект со следующими свойствами:
   * - `width`: Новая ширина окна в пикселях.
   * - `name`: Строка имени, связанная с событием изменения размера.
   *
   * @type {Subject<{width: number, name: string}>}
   */
  readonly resizeEvent$ = new Subject<{ width: number; name: string }>();

  /**
   * Представляет сигнал, указывающий, происходит ли в настоящее время действие изменения размера.
   *
   * @type {Signal<boolean>}
   */
  readonly #isResizing = signal(false);

  /**
   * Указывает, происходит ли в настоящее время изменение размера элемента.
   *
   * @type {boolean}
   */
  readonly isResizing = this.#isResizing.asReadonly();

  /**
   * Устанавливает статус изменения размера.
   *
   * @param {boolean} isResizing - Логическое значение, указывающее, происходит ли сейчас изменение размера или нет.
   * @return {void}
   */
  setIsResizing(isResizing: boolean): void {
    this.#isResizing.set(isResizing);
  }

  /**
   * Инициализирует ширину панели избранного панели инструментов.
   * Извлекает значение ширины панели из локального хранилища и устанавливает его в качестве значения CSS-переменной.
   */
  getWidthFromLocalStorage(name: string): number | undefined {
    const panelWidth = this.#window.localStorage.getItem(name);

    return panelWidth ? parseInt(panelWidth) : undefined;
  }

  /**
   * Сохраняет ширину панели избранного панели инструментов в локальное хранилище.
   *
   * @returns {void}
   */
  saveWidthToLocalStorage(name: string, width: number): void {
    if (width) {
      this.#window.localStorage.setItem(name, width + '');
    }
  }
}
