import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, inject, input, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { interval } from 'rxjs';

import { AvifPipe } from '../../pipe/avif/avif.pipe';
import { INTERVAL_TIME } from './intro-slider.constant';

/**
 * Представляет компонент, который отображает слайдер с изображениями.
 * Данный компонент использует библиотеку KeenSlider.
 *
 * @selector app-keen-slider
 * @standalone true
 * @imports CommonModule, AvifPipe
 * @templateUrl ./keen-slider.component.html
 * @styleUrls ./keen-slider.component.scss
 * @changeDetection OnPush
 */
@Component({
  selector: 'app-intro-slider',
  standalone: true,
  imports: [CommonModule, AvifPipe, NgOptimizedImage],
  templateUrl: './intro-slider.component.html',
  styleUrls: ['./intro-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntroSliderComponent implements OnInit {
  /**
   * Представляет собой коллекцию слайдов.
   *
   * @typedef {Object[]} Slides
   * @property {string} image - Путь к изображению для каждого слайда.
   */
  slides = input.required<{ image: string }[]>();

  /**
   * Содержит индекс текущего слайда в слайдшоу.
   *
   * @type {Signal<number>}
   * @memberof MySlideshowApp
   */
  currentSlideIndex = signal<number>(0);

  /**
   * Представляет собой ссылку на экземпляр класса DestroyRef.
   *
   * @class
   * @name destroyRef
   * @type {DestroyRef}
   *
   * @example
   * // Пример использования:
   * const destroyRef = inject(DestroyRef);
   */
  #destroyRef = inject(DestroyRef);

  /**
   * Инициализирует компонент и настраивает интервал для переключения слайдов.
   * @returns {void}
   */
  ngOnInit(): void {
    interval(INTERVAL_TIME)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => {
        const prevIndex = this.currentSlideIndex();
        const index = prevIndex + 1 > this.slides().length - 1 ? 0 : prevIndex + 1;
        this.currentSlideIndex.set(index);
      });
  }
}
