import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, catchError, map, retry, take, tap } from 'rxjs';

import { MATCHMAKER_RETRY_DELAY } from '../constants/app.constant';
import { FeatureFlagService } from '../modules/feature-flag/feature-flag.service';
import { WINDOW } from '../tokens/window.token';
import { ConfigService } from './config.service';
import { MainHtmlPreloaderService } from './main-html-preloader.service';

/**
 * Константа, содержащая URL сервера сигнализации для матчмейкера.
 * @type {string}
 */
export const MATCHMAKER_URL_SIGNALLING_SERVER = 'MATCHMAKER_URL_SIGNALLING_SERVER';

/**
 * Интерфейс для ответа с URL сервера сигнализации
 * @typedef {Object} SignalingServerUrlRes
 * @property {string} signallingServer - URL сервера сигнализации
 * @property {string} [error] - Ошибка, если есть
 */
interface SignalingServerUrlRes {
  /**
   * Строка, содержащая адрес сервера сигнализации для установления соединения
   * @type {string}
   */
  signallingServer: string;
  /**
   * Описание ошибки, если она есть
   *
   * @type {string}
   */
  error?: string;
}

/**
 * MatchmakerService
 *
 * Этот класс отвечает за связь с сервером Matchmaker для получения URL сигнализационного сервера.
 *
 * @Injectable({ providedIn: 'root' })
 */
@Injectable({ providedIn: 'root' })
export class MatchmakerService {
  /**
   * Создает экземпляр конструктора.
   *
   * @param {Window} window - Объект window, внедренный в конструктор.
   * @param {HttpClient} httpClient - HTTP-клиент для обработки HTTP-запросов.
   * @param {MainHtmlPreloaderService} mainHtmlPreloaderService - Сервис основного HTML прелоадера для выполнения задач предзагрузки.
   * @param {FeatureFlagService} featureFlagService - Сервис featureFlag, внедренный в конструктор.
   * @param {ConfigService} configService - сервис для управления конфигурацией приложения
   */
  constructor(
    @Inject(WINDOW) private window: Window,
    private httpClient: HttpClient,
    private mainHtmlPreloaderService: MainHtmlPreloaderService,
    private featureFlagService: FeatureFlagService,
    private configService: ConfigService,
  ) {}

  /**
   * Получает URL сигнализационного сервера из конечной точки MATCHMAKER_URL.
   *
   * @returns Observable, который выдает URL сигнализационного сервера в виде строки.
   */
  getSignalingServerUrl(): Observable<string> {
    const signalingServerUrl = this.window.localStorage.getItem(MATCHMAKER_URL_SIGNALLING_SERVER);

    const url$ = this.featureFlagService.isFeatureOn('MATCH_MAKER_ENABLE_CHECK')
      ? signalingServerUrl
        ? this.checkSignalingServer(signalingServerUrl)
        : this.signallingServer()
      : this.signallingServer();

    return url$.pipe(
      tap(({ error, signallingServer }) => !error && this.window.localStorage.setItem(MATCHMAKER_URL_SIGNALLING_SERVER, signallingServer)),
      map(({ signallingServer, error }) => {
        this.mainHtmlPreloaderService.matchmakerIsUnavailable(false);
        this.mainHtmlPreloaderService.changeSubTitleForSearchServer();

        if (error) {
          console.error('Signaling server url error', error);
          throw new Error(error);
        }

        // remove port (ex :443) if any
        if (signallingServer.includes(':')) {
          signallingServer = signallingServer.replace(/:.*/, '');
        }

        return `${this.configService.getValue('MATCHMAKER_URL').startsWith('https') ? 'wss' : 'ws'}://${signallingServer}`;
      }),
      catchError((error) => {
        if (error.status === 503 || error.status === 0) {
          this.mainHtmlPreloaderService.matchmakerIsUnavailable(true);
        } else {
          this.mainHtmlPreloaderService.changeSubTitleForSearchServer();
        }

        throw error;
      }),
      retry({ delay: MATCHMAKER_RETRY_DELAY, count: 22 }),
    );
  }

  /**
   * Проверяет доступность сервера сигнализации по указанному адресу.
   *
   * @param {string} address - Адрес сервера сигнализации.
   * @returns {Observable<SignalingServerUrlRes>} - Observable с результатом проверки доступности сервера сигнализации.
   */
  private checkSignalingServer(address: string): Observable<SignalingServerUrlRes> {
    return this.httpClient.post<{ available: boolean; signallingServer: string }>(`${this.getMatchmakerUrl()}/check`, { address }).pipe(
      take(1),
      map(({ available, signallingServer }) => {
        const server = available ? address : signallingServer;

        if (!server) {
          this.window.localStorage.removeItem(MATCHMAKER_URL_SIGNALLING_SERVER);
        }

        return { signallingServer: server, error: server ? undefined : 'No Signaling server' };
      }),
      catchError((error) => {
        console.error('checkSignalingServerUrl', error);
        this.window.localStorage.removeItem(MATCHMAKER_URL_SIGNALLING_SERVER);
        throw error;
      }),
    );
  }

  /**
   * Получает URL сервера сигнализации с помощью HTTP-запроса к серверу матчмейкера.
   * @returns {Observable<SignalingServerUrlRes>} Observable с URL сервера сигнализации.
   */
  private signallingServer(): Observable<SignalingServerUrlRes> {
    return this.httpClient.get<SignalingServerUrlRes>(`${this.getMatchmakerUrl()}/signallingserver`).pipe(take(1));
  }

  /**
   * Получает URL матчмейкера.
   * Если URL содержит '/signallingserver', то возвращает URL без этой части,
   * иначе возвращает исходный URL.
   *
   * @returns {string} URL матчмейкера
   */
  private getMatchmakerUrl(): string {
    const matchmakerUrl = this.configService.getValue('MATCHMAKER_URL');
    return matchmakerUrl.includes('/signallingserver') ? matchmakerUrl.replace('/signallingserver', '') : matchmakerUrl;
  }
}
