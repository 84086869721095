<div class="slider-wrapper">
  <div class="intro-slider">
    @for (slide of slides(); track $index) {
      <div class="slide fade-virtual" [class.active]="currentSlideIndex() === $index">
        <picture>
          <source type="image/avif" [srcset]="slide.image | avif" />
          <img alt="slide logo" i18n-alt="@@slider.image.alt" [src]="slide.image" />
        </picture>
      </div>
    }
  </div>
</div>
