import { Pipe, PipeTransform } from '@angular/core';

import { ConfigKeys, FeatureFlagService } from '../../feature-flag/feature-flag.service';

/**
 * Пользовательский канал для проверки видимости элемента меню.
 * @param name - Название канала
 * @param standalone - Флаг, указывающий на то, что канал является автономным
 */
@Pipe({
  name: 'isMenuItemVisible',
  standalone: true,
})
export class IsMenuItemVisiblePipe implements PipeTransform {
  /**
   * Конструктор класса IsMenuItemVisiblePipe
   * @param featureFlagService - Сервис флагов функций
   */
  constructor(private featureFlagService: FeatureFlagService) {}

  /**
   * Метод преобразования для проверки видимости элемента меню
   * @param key - Ключ элемента меню
   * @returns {boolean} - Результат проверки видимости элемента меню
   */
  transform(key: object | string): boolean {
    if (typeof key === 'string') {
      const configKey = key as ConfigKeys;

      if (this.featureFlagService.isFeatureOn('PROFILE') && configKey === 'SETTINGS') {
        return false;
      }

      return this.featureFlagService.isFeatureOn(configKey);
    }

    return false;
  }
}
