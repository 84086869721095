/**
 * Проверяет, является ли данный объект пустым.
 *
 * @template T - Тип объекта
 * @param {T} obj - Проверяемый объект
 * @returns {boolean} - Если объект пустой, возвращается true, иначе false
 * @throws {Error} - Если объект null или undefined
 * @throws {Error} - Если во время проверки происходит ошибка
 */
export const isEmpty = <T extends Record<number | string, unknown> | string | { [id: number]: unknown } | null | undefined>(
  obj: T,
): boolean => {
  try {
    if (typeof obj === 'string' && !obj) {
      return false;
    }

    if (obj) {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    } else {
      throw new Error('TypeError: Cannot convert undefined or null to object');
    }
  } catch {
    throw new Error('isEmpty Error');
  }
};
