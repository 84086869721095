// https://www.notion.so/theinvaders/5e17016b8f064ccaa6d6bb8bf2032c2c - здесь лежат значения всех API контрактов
export const RpcMessagesBackend = {
  TRANSLATE_CAMERA: 'translateCamera', // Перелёт камеры к объекту
  TRANSLATE_CAMERA_TO_OBJECT: 'translateCameraToObject', // Перелёт камеры к объекту
  TELEPORT_CAMERA_TO_OBJECT: 'teleportCameraToObject', // Телепорт камеры к объекту в случае если есть hash в url
  TELEPORT_CAMERA: 'teleportCamera', // Телепорт камеры к объекту в случае если есть hash в url
  GET_UE_VERSION: 'getUnrealProjectVersion', // Запрос версии UE
  GET_LAYERS: 'getLayers', // Запрос layers flags
  TOGGLE_LAYERS: 'toggleLayers', // Включение и отключение одного ио группы layers
  PING: 'ping',
  GET_CURRENT_BUILDINGS: 'getCurrentBuildings',
  GET_GEN_URBAN_DESIGN_STATE: 'getGenUrbanDesignState',
  GET_LAND_AREA_VALUE: 'getLandAreaValue',
  TOGGLE_FEATURE: 'toggleFeature',
  TOGGLE_GEN_URBAN_DESIGN_PANEL: 'toggleGenUrbanDesignPanel',
  IS_GEN_URBAN_DESIGN_PANEL_ACTIVE: 'isGenUrbanDesignPanelActive',
  TOGGLE_GEOGRAPHIC_TOOL_PANEL: 'toggleGeographicToolPanel',
  TRY_OPEN_OBJECT_CARD: 'tryOpenObjectCard',
  GET_LEVELS_LIST: 'getLevelsList', // Запрос всех доступных левелов из Unreal Engine
  OPEN_LEVEL_BY_NAME: 'openLevelByName',
  DESTROY_SEARCH_ANCHOR: 'destroySearchAnchor', // Запрос на удаление метки объекта поиска
  MAC_RIGHT_MOUSE_CLICK: 'macRightMouseClick',
  SEARCH_BUILDINGS_BY_KEYWORDS: 'searchBuildingsByKeywords',
  SET_LIGHT_TYPE: 'setLightType',
  GET_LIGHT_TYPE: 'getLightType',
  GET_CONTROL_MODE: 'getControlMode',
  SET_CONTROL_MODE: 'setControlMode',
  SET_GEN_URBAN_DESIGN_STATE: 'setGenUrbanDesignState',
  DESTROY_AREA: 'destroyArea',
  EXIT_CHARACTER_PLACEMENT_MODE: 'exitCharacterPlacementMode',
  TRANSLATE_CAMERA_TO_BUILDING: 'translateCameraToBuilding',
  TELEPORT_CAMERA_TO_BUILDING: 'teleportCameraToBuilding',
  GET_CURRENT_BUILDING_OPTION: 'getCurrentBuildingOption',
  SET_BUILDINGS_OPTION: 'setBuildingsOption',
  SET_GLOBE_URL: 'setGlobeUrl',
  ACTIVATE_BUILDING_DEMO_MODE: 'activateBuildingDemoMode',
  RESET_CONTROLS: 'resetControls',
} as const;

export type RpcMessagesBackend = (typeof RpcMessagesBackend)[keyof typeof RpcMessagesBackend];

export const RpcMessagesFrontend = {
  LOCK_CURSOR: 'lockCursor', // Блокировка и скрытие курсора
  UNLOCK_CURSOR: 'unlockCursor', // Разблокировка и отображение курсора
  SET_CARTOGRAPHIC_DRAG_ENABLED: 'setCartographicDragEnabled', // Установка курсора в виде руки в картографическом режиме
  OPEN_OBJECT_CARD: 'openObjectCard', // Запрос информации об объекте
  CLOSE_OBJECT_CARD: 'closeObjectCard', // Закрытие карточки с информацией об объекте
  SHOW_LOADING_SCREEN: 'showLoadingScreen',
  HIDE_LOADING_SCREEN: 'hideLoadingScreen',
  CLEAR_SEARCH_PATH: 'clearSearchPath',
  SCREEN_CALIBRATION: 'screenCalibration',
  SET_CAMERA_COORDINATES: 'setCameraCoordinates',
  UPDATE_CONTROL_MODE: 'updateControlMode',
  UPDATE_LIGHT_ACTIVATION_TYPE: 'updateLightActivationType',
  UPDATE_GENERATION_PHASE_TYPE: 'onChangeGenerationPhase',
} as const;

export type RpcMessagesFrontend = (typeof RpcMessagesFrontend)[keyof typeof RpcMessagesFrontend];
