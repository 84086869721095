import { Injectable } from '@angular/core';

export type ConfigKeys =
  | '3D-MODELS'
  | 'ACT_AGR_ID'
  | 'AUTO_CALIBRATION'
  | 'BUILDINGS_OPTIONS'
  | 'CONFIG'
  | 'DESTROY_SEARCH_ANCHOR'
  | 'FAVORITES_WITH_FOLDERS'
  | 'FAVORITES'
  | 'GLOBE_URL'
  | 'LAYERS'
  | 'LEVELS'
  | 'LIGHT_TOGGLE'
  | 'MAP_CENTER'
  | 'MATCH_MAKER_ENABLE_CHECK'
  | 'MATCH_MAKER'
  | 'OBJECT_CARD_ENABLED'
  | 'OBJECT_CARD_IMAGE_ENABLED'
  | 'OBJECT_CARD_PRESENTATION_ENABLED'
  | 'PCG_OLD'
  | 'PCG'
  | 'PROFILE'
  | 'RECONNECT'
  | 'RPC_LOGS_ENABLED'
  | 'SEARCH'
  | 'SET_BUILDING_OPTION'
  | 'SETTINGS'
  | 'SHOW_PING_ERROR'
  | 'SHOW_PIXEL_STREAMING_EXTENDED_LOGS'
  | 'SHOW_PIXEL_STREAMING_LOGS'
  | 'STRATUMS_IS_MOCKS_API'
  | 'STRATUMS'
  | 'TELEPORT_CAMERA_TO_OBJECT'
  | 'TELEPORT_CAMERA'
  | 'UE_PING'
  | 'USE_SMART_AUTH'
  | 'USER_SETTINGS_API'
  | 'USER-MANUAL';
export type Config = Record<ConfigKeys, boolean>;

/**
 * Представляет собой сервис для обработки флагов функций.
 *
 * @remarks
 * Этот класс позволяет проверить, включена ли функция и загрузить конфигурацию с сервера.
 *
 * @example
 * const isFeatureOn = featureService.isFeatureOn('feature1');
 *
 */
@Injectable()
export class FeatureFlagService {
  /**
   * Представляет конфигурационный объект.
   *
   * @typedef {Object} Config
   * @property {string} option1 - Значение для варианта 1.
   * @property {number} option2 - Значение для варианта 2.
   * @property {boolean} option3 - Значение для варианта 3.
   * @property {Array} option4 - Массив значений для варианта 4.
   */
  #config?: Config;

  /**
   * Проверяет, включена ли данная функция.
   *
   * @param {ConfigKeys} featureName - Название функции для проверки.
   * @return {boolean} - Возвращает true, если функция включена, иначе false.
   */
  isFeatureOn(featureName?: ConfigKeys): boolean {
    return featureName && this.#config ? (this.#config[featureName] ?? false) : false;
  }

  /**
   * Устанавливает конфигурацию для объекта.
   *
   * @param {Config} config - Конфигурация для установки.
   * @return {void}
   */
  setConfig(config: Config): void {
    this.#config = config;
  }
}
