import { Inject, Injectable, signal } from '@angular/core';
import { Observable, of, tap } from 'rxjs';

import { WINDOW } from '../../tokens/window.token';
import { UserSettings } from '../../types/user-settings.interface';
import { DEFAULT_SETTINGS, USER_SETTINGS_KEY } from './user-settings.constant';
import { UserSettingsProvider } from './user-settings.interface';

/**
 * Сервис для работы с настройками пользователя в локальном хранилище.
 * @implements {UserSettingsProvider}
 */
@Injectable({ providedIn: 'root' })
export class UserSettingsLocalstorageService implements UserSettingsProvider {
  /**
   * Устанавливает настройки пользователя с помощью сигнала.
   *
   * @param {UserSettings} DEFAULT_SETTINGS - Настройки пользователя по умолчанию.
   * @returns {signal<UserSettings>} - Сигнал с установленными настройками пользователя.
   */
  settings = signal<UserSettings>(DEFAULT_SETTINGS);

  /**
   * Конструктор класса.
   * @param {Window} window - Объект Window для доступа к локальному хранилищу.
   */
  constructor(@Inject(WINDOW) private window: Window) {}

  /**
   * Получение настроек пользователя из локального хранилища.
   * @returns {Observable<UserSettings>} - Наблюдаемый поток с настройками пользователя.
   */
  getSettings(): Observable<UserSettings> {
    const userSettingsValue = this.window.localStorage.getItem(USER_SETTINGS_KEY);

    if (!userSettingsValue) {
      this.settings.set(DEFAULT_SETTINGS);

      return of(DEFAULT_SETTINGS);
    }

    let userSettings: UserSettings;

    try {
      userSettings = JSON.parse(userSettingsValue);
    } catch (err) {
      console.error('JSON parse error for userSettings in localStorage', err);
      this.settings.set(DEFAULT_SETTINGS);

      return of(DEFAULT_SETTINGS);
    }

    return of(userSettings).pipe(tap((data) => this.settings.set(data)));
  }

  /**
   * Частичное обновление настроек пользователя и сохранение их в локальном хранилище.
   * @param {Partial<UserSettings>} settings - Частичные настройки пользователя для обновления.
   * @returns {Observable<UserSettings>} - Наблюдаемый поток с обновленными настройками пользователя.
   */
  patchSettings(settings: Partial<UserSettings>): Observable<UserSettings> {
    const userSettings = { ...this.settings(), ...settings };
    this.window.localStorage.setItem(USER_SETTINGS_KEY, JSON.stringify(userSettings));

    return of(userSettings);
  }
}
