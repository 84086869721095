<button
  blurAfterFocus
  class="btn"
  i18n-matTooltip="@@center-map.tooltip"
  matTooltip="Переход к исходному виду"
  type="button"
  [disabled]="isTranslating()"
  (click)="translateCamera2CenterMap()"
>
  <svg-icon class="btn__icon" name="centerMap" />
</button>
