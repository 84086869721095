import { Inject, Injectable } from '@angular/core';
import { filter, fromEvent, map, tap } from 'rxjs';
import { share } from 'rxjs/operators';

import { WINDOW } from '../tokens/window.token';
import {
  DtPostmessageBackend,
  DtPostmessageEventData,
  DtPostmessageParams,
  DtPostmessageResult,
} from '../types/dt-jsonrpc-postMessage.interface';
import { generateGUID } from '../utils/uuid.util';

/**
 * Сервис для отправки событий postMessage и обработки полученных сообщений.
 *
 * @remarks
 * Класс `DtPostmessageService` предоставляет методы для отправки событий `postMessage`
 * и обрабатывает полученные сообщения в контексте приложения Angular.
 *
 */
@Injectable({
  providedIn: 'root',
})
export class DtPostmessageService {
  /**
   * Обозначает переменную dtWindow.
   *
   * @type {MessageEventSource | null}
   */
  #dtWindow: MessageEventSource | null = null;

  /**
   * Представляет переменную с именем #postMessage$.
   * Это Observable, который выдает объекты MessageEvent, содержащие DtPostmessageEventData.
   * Observable создается с использованием метода 'fromEvent' объекта 'window' с именем события 'message'.
   * Фильтрует события на основе условия, что существует `event.data.jsonrpc`.
   * Также ведет логирование информации о методе и параметрах события с использованием оператора `tap`.
   * Observable также делится между несколькими подписчиками с использованием оператора `share`.
   *
   * @type {Observable<MessageEvent<DtPostmessageEventData>>}
   */
  #postMessage$ = fromEvent<MessageEvent<DtPostmessageEventData>>(this.window, 'message').pipe(
    filter((event) => !!event.data.jsonrpc),
    tap((event) =>
      console.info('PostMessage method: ', {
        method: event.data.method,
        params: event.data.params,
        id: event.data.guid,
        result: event.data.result,
      }),
    ),
    share(),
  );

  /**
   * Наблюдаемый объект, который фильтрует и отображает события postMessage, связанные с установкой видимости интерфейса.
   *
   * @type {Observable}
   */
  setInterfaceVisibility$ = this.#postMessage$.pipe(
    filter((event) => event.data.method === 'setInterfaceVisibility'),
    map((event) => event.data),
  );

  /**
   * Представляет собой поток событий postMessage, отфильтрованный так, чтобы включать только события с методом "hideInterface".
   *
   * @type {Observable}
   */
  getInterfaceVisibility$ = this.#postMessage$.pipe(
    filter((event) => event.data.method === 'getInterfaceVisibility'),
    map((event) => event.data),
  );

  /**
   * Создает новый экземпляр класса Constructor.
   *
   * @param {Window} window - Внедряемый объект окна.
   *
   */
  constructor(@Inject(WINDOW) private window: Window) {}

  /**
   * Инициализирует метод.
   *
   * @returns {Promise<void>} - Promise, которое разрешается, когда инициализация завершена.
   *
   */
  init(): Promise<void> {
    return new Promise((resolve) => {
      this.#postMessage$.subscribe((event) => {
        if (event.source) {
          this.#dtWindow = event.source;
        } else {
          console.error('Message event has no source:', event);
        }
      });
      resolve();
    });
  }

  /**
   * Отправляет сообщение postMessage родительскому окну iframe.
   *
   * @param {DtPostmessageBackend} method - Метод, который должен быть вызван принимающей стороной.
   * @param {DtPostmessageParams} params - Параметры, которые должны быть переданы принимающей стороне.
   *
   * @return {void}
   */
  sendPostMessage({
    id = generateGUID(),
    ...data
  }: {
    id?: string;
    method?: DtPostmessageBackend;
    params?: DtPostmessageParams;
    result?: DtPostmessageResult;
  }): void {
    if (!this.#dtWindow) {
      console.error('No Parent IFrame Window object');
      return;
    }

    this.#dtWindow?.postMessage(
      {
        jsonrpc: '2.0',
        guid: id,
        ...data,
      },
      { targetOrigin: '*' },
    );
  }
}
