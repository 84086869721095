import '@angular/localize/init';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { Config, FeatureFlagService } from './app/modules/feature-flag/feature-flag.service';

/**
 * Загружает конфигурацию из файла feature-flag.json.
 *
 * @returns {Promise<Config | undefined>} - Обещание выполнения с объектом конфигурации или undefined, если загрузка не удалась
 */
const loadConfig = async (): Promise<Config | undefined> => {
  const uniqueParam = new Date().getTime();
  const url = `./config/feature-flag.json?cacheBuster=${uniqueParam}`;

  const response = await fetch(url);

  return await response.json();
};

(async (): Promise<void> => {
  const config = await loadConfig();

  if (!config) {
    console.error('Ошибка загрузки feature-flag.json');
    return;
  }

  const featureFlagService = new FeatureFlagService();
  featureFlagService.setConfig(config);

  platformBrowserDynamic([{ provide: FeatureFlagService, useValue: featureFlagService }])
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
})();
