import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialogClose } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';

import { IconType } from '../../svg-icon/generated-svg/svg-icons';
import { SvgIconModule } from '../../svg-icon/svg-icon.module';

/**
 * Представляет компонент Пользовательского Руководства.
 * Обеспечивает пользовательскую инструкцию по использованию приложения.
 */
@Component({
  selector: 'app-user-manual',
  standalone: true,
  imports: [MatExpansionModule, SvgIconModule, MatDialogClose, MatButton, NgOptimizedImage],
  templateUrl: './user-manual.component.html',
  styleUrl: './user-manual.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserManualComponent {
  //todo: mock data - remove
  /**
   * Массив подпунктов боковой панели.
   * @type {string[]}
   */
  readonly sidebarSubItems = [
    $localize`:@@user-manual.subItem.planet-mode:Режим Планеты`,
    $localize`:@@user-manual.subItem.cartographic-mode:Картографический режим`,
    $localize`:@@user-manual.subItem.freecam-mode:Режим свободной камеры`,
    $localize`:@@user-manual.subItem.character-mode:Режим персонажа`,
    $localize`:@@user-manual.subItem.vehicle-mode:Режим автомобиля`,
  ];

  //todo: mock data - remove
  /**
   * Представляет элементы боковой панели.
   * @typedef {Object} SidebarItem
   * @property {string} title - Заголовок элемента боковой панели.
   * @property {string[]} subItems - Подэлементы элемента боковой панели.
   * @property {string} icon - Тип иконки элемента боковой панели.
   */
  readonly sidebarItems: { title: string; subItems: string[]; icon: IconType }[] = [
    {
      title: $localize`:@@user-manual.item-title.interface:Интерфейс`,
      subItems: this.sidebarSubItems,
      icon: 'interface',
    },
    {
      title: $localize`:@@user-manual.item-title.control:Управление`,
      subItems: this.sidebarSubItems,
      icon: 'control',
    },
    {
      title: $localize`:@@user-manual.item-title.layers:Слои`,
      subItems: this.sidebarSubItems,
      icon: 'layersManual',
    },
    {
      title: $localize`:@@user-manual.item-title.interactiveObjects:Интерактивные объекты`,
      subItems: this.sidebarSubItems,
      icon: 'interactiveObjects',
    },
    {
      title: $localize`:@@user-manual.item-title.microregion:Здания`,
      subItems: this.sidebarSubItems,
      icon: 'microregion',
    },
  ];
}
