import { Inject, Injectable, signal } from '@angular/core';

import { WINDOW } from '../../tokens/window.token';
import { generateGUID } from '../../utils/uuid.util';
import { ControlMode } from '../control-modes/control-modes.interface';
import { OBJECT_CARD_TITLE } from './object-card.enum';
import {
  GroupedProperties,
  OBJECT_CARD_VIEW_TYPE,
  ObjectInfo,
  Property,
  PropertyGroup,
  PropertyGroupKeysOrder,
  View,
} from './object-card.interface';

/**
 * Сервис для управления данными карточки объекта.
 */
@Injectable({ providedIn: 'root' })
export class ObjectCardService {
  /**
   * Представляет начальное значение для переменной `additionData`.
   *
   * @type {Object}
   * @property {string} name - Имя данных добавления.
   * @property {string} imageBase64 - Строка с кодировкой base64, представляющая изображение.
   * @property {ObjectInfo['features']['geometry'] | undefined} geometry - Геометрическая информация данных добавления.
   */
  #additionDataInitialValue: {
    name: string;
    geometry?: ObjectInfo['features']['geometry'];
    title?: ObjectInfo['title'];
    icon?: ObjectInfo['icon'];
    hasDemoViewMode: boolean;
    hasBuildingsOptionIcon: boolean;
    buildingId: string;
    useOldApiBuildingOption?: boolean;
  } = {
    name: '',
    geometry: undefined,
    icon: 'objectCard',
    hasBuildingsOptionIcon: false,
    hasDemoViewMode: false,
    buildingId: '',
  };

  /**
   * Представляет свойства сигнала.
   * @typedef {Object} Property
   * @property {string} name - Имя свойства.
   * @property {string} value - Значение свойства.
   */
  groupedProperties = signal<GroupedProperties | null>(null);
  /**
   * Представляет дополнительные данные для сигнала.
   *
   * @class
   *
   * @property {Signal} additionData - Сигнал, содержащий дополнительные данные.
   */
  additionData = signal(this.#additionDataInitialValue);

  /**
   * Получить представление карточки объекта, связанное с этим объектом.
   *
   * @returns {Object} Представление карточки объекта.
   */
  objectCardView = this.getObjectCardView();

  /**
   * Свойство отображения ошибка загрузки изображения, если она есть
   * @type {Signal<Event | null>}
   * @param {Event | null} payload - Параметр, содержащий событие ошибки или null, если загрузка изображения завершилась успешно.
   */
  readonly imageState = signal<{ loaded: boolean; error: Event | null }>({ loaded: false, error: null });

  /**
   * Представляет список свойств.
   *
   * @typedef {Object} Property
   * @property {string} text - Текст отображения для свойства.
   * @property {string} icon - Иконка, связанная со свойством.
   * @property {string} code - Код, представляющий свойство.
   * @property {string} group - Принадлежность определеной группе.
   */
  #propertiesList: Omit<Property, 'value'>[] = [
    { text: $localize`Адрес`, icon: 'locationOn', code: 'address', group: PropertyGroup.GENERAL },
    { text: $localize`Округ`, icon: 'earthBox', code: 'okrug', group: PropertyGroup.GENERAL },
    { text: $localize`Район`, icon: 'district', code: 'rajon', group: PropertyGroup.GENERAL },
    { text: $localize`Кадастровый номер`, icon: 'description', code: 'cadNum', group: PropertyGroup.GENERAL },
    { text: $localize`Координаты WGS 84`, icon: 'myLocation', code: 'coordinates', group: PropertyGroup.GENERAL },
    { text: $localize`Проектная организация`, icon: 'architecture', code: 'designer', group: PropertyGroup.DEVELOPER },
    { text: $localize`Застройщик`, icon: 'dumpTruck', code: 'developer', group: PropertyGroup.DEVELOPER },
    { text: $localize`Функциональное назначение`, icon: 'corporateFare', code: 'FNO_name', group: PropertyGroup.DEVELOPER },
    { text: $localize`Код функционального назначения`, icon: 'dataArray', code: 'FNO_code', group: PropertyGroup.DEVELOPER },
    { text: $localize`Акт АГР`, icon: 'tag', code: 'act_AGR', group: PropertyGroup.DEVELOPER },
    { text: $localize`Площадь участка, га`, icon: 'textureBox1', code: 'ZU_area', group: PropertyGroup.SQUARE },
    { text: $localize`Общая площадь объекта, м<sup>2</sup>`, icon: 'square', code: 's_obsh', group: PropertyGroup.SQUARE },
    { text: $localize`Наземная площадь объекта, м<sup>2</sup>`, icon: 'groundArea', code: 's_naz', group: PropertyGroup.SQUARE },
    { text: $localize`Подземная площадь объекта, м<sup>2</sup>`, icon: 'undergroundArea', code: 's_podz', group: PropertyGroup.SQUARE },
    { text: $localize`Суммарная поэтажная площадь, м<sup>2</sup>`, icon: 'floorPlan', code: 'spp_gns', group: PropertyGroup.SQUARE },
    { text: $localize`Абсолютная высота объекта, м`, icon: 'absoluteHeight', code: 'h_abs', group: PropertyGroup.HEIGHT },
    { text: $localize`Относительная высота объекта, м`, icon: 'height', code: 'h_otn', group: PropertyGroup.HEIGHT },
    { text: $localize`Нулевая отметка, м`, icon: 'verticalAlignBottom', code: 'h_relief', group: PropertyGroup.HEIGHT },
  ];

  /**
   * Представляет свойства объекта в информации об объекте.
   * @typedef {ObjectInfo['features']['properties'] | null} objectInfoFeaturesProperties
   */
  #objectInfoFeaturesProperties: ObjectInfo['features']['properties'] | null = null;

  /**
   * Представляет типы свёрнутых групп.
   *
   * @type {PropertyGroup['type'][]}
   */
  #collapsedGroupsType: PropertyGroup['type'][] = this.getCollapsedGroupType();

  /**
   * Создает экземпляр конструктора.
   *
   * @param {Window} window - Внедренный объект окна.
   *
   */
  constructor(@Inject(WINDOW) private window: Window) {}

  /**
   * Устанавливает данные для объекта.
   *
   * @param {ObjectInfo} objectInfo - Информационный объект, содержащий данные.
   * @return {void} - Без возвращаемого значения.
   */
  setData(objectInfo: ObjectInfo): void {
    const prevBuildingId = this.additionData().buildingId;

    const currentBuildingId = objectInfo.buildingId;

    if (currentBuildingId && prevBuildingId === currentBuildingId) {
      return;
    }

    this.imageState.set({ error: null, loaded: false });
    const props = objectInfo.features.properties;
    this.#objectInfoFeaturesProperties = props;
    const groupedProperties = this.#propertiesList.reduce((acc, item) => {
      if (item.code === 'coordinates') {
        if (objectInfo.features.geometry) {
          acc = this.pushProp(acc, item, objectInfo.features.geometry.coordinates.toString().replace(',', ', '));
        }

        return acc;
      } else {
        let value: number | string = '';
        const prop = props[item.code];

        if (prop?.isEnabled) {
          value = prop.value;
        }

        if (typeof value === 'number') {
          value = this.twoNumbersAfterDot(value);
        }

        if (value) {
          acc = this.pushProp(acc, item, value);
        }

        return acc;
      }
    }, {} as GroupedProperties);

    this.additionData.set({
      buildingId: objectInfo.buildingId,
      name: props.name.value,
      geometry: objectInfo.features.geometry,
      title: objectInfo.title,
      icon: objectInfo.icon ?? this.#additionDataInitialValue.icon,
      hasDemoViewMode: !!objectInfo.features.hasDemoViewMode,
      hasBuildingsOptionIcon: !!objectInfo.features.hasBuildingsOptionIcon,
      useOldApiBuildingOption: objectInfo.useOldApiBuildingOption,
    });
    this.groupedProperties.set(groupedProperties);
  }

  /**
   * Извлекает два числа после десятичной точки из заданного значения.
   *
   * @param {number} value - Значение, из которого нужно извлечь два числа после десятичной точки.
   * @return {number} - Извлеченные два числа после десятичной точки. Если значение не содержит десятичной точки, возвращается исходное значение.
   */
  twoNumbersAfterDot(value: number): number {
    if (value.toString().includes('.')) {
      return +(Math.round(value * 100) / 100).toString().replace(/\.(\d{1,2}).*$/, '.$1');
    } else {
      return value;
    }
  }

  /**
   * Очищает данные, сбрасывая значения additionData и properties.
   *
   * @returns {void}
   */
  clearData(): void {
    this.imageState.set({ error: null, loaded: false });
    this.additionData.set(this.#additionDataInitialValue);
    this.groupedProperties.set(null);
    this.#objectInfoFeaturesProperties = null;
  }

  /**
   * Переключает состояние свертывания конкретной группы в сгруппированных свойствах.
   *
   * @param {GroupedProperties[keyof GroupedProperties]} group - Группа для переключения.
   * @return {void}
   */
  toggleCollapsedGroup(group: GroupedProperties[keyof GroupedProperties]): void {
    this.groupedProperties.update((groupedProperties) => {
      if (!groupedProperties) {
        return groupedProperties;
      }

      groupedProperties[group.type].collapsed = !groupedProperties[group.type].collapsed;
      return groupedProperties;
    });
  }

  /**
   * Обновляет свернутые группы сгруппированных свойств на основе указанного типа вида.
   * @param {View} type - Тип вида для обновления свернутых групп.
   * @return {void}
   */
  updateCollapsedGroups(type: View): void {
    this.groupedProperties.update((groupedProperties) => {
      if (!groupedProperties) {
        return groupedProperties;
      }

      PropertyGroupKeysOrder.forEach((groupType) => {
        if (groupedProperties[groupType]) {
          if (type === View.MINIMUM || type === View.REVEALED) {
            groupedProperties[groupType].collapsed = type === View.MINIMUM;
          } else {
            groupedProperties[groupType].collapsed = groupType !== PropertyGroup.GENERAL.type;
          }
        }
      });

      this.window.localStorage.setItem(OBJECT_CARD_VIEW_TYPE, type + '');
      this.objectCardView = this.getObjectCardView();
      this.#collapsedGroupsType = this.getCollapsedGroupType();

      return groupedProperties;
    });
  }

  /**
   * Конвертирует объект value в объект ObjectInfo.
   *
   * @param {Object} data - Объект value для конвертации.
   * @param {string} [data.name] - Имя объекта.
   * @param {string} [data.address] - Адрес объекта.
   * @param {string} [data.center] - Координаты центра объекта в формате JSON.
   * @return {ObjectInfo} Конвертированный объект ObjectInfo.
   */
  docToObjectInfo({ name, address, center }: { name?: string; address?: string; center?: string }): ObjectInfo {
    let coordinates = [];

    if (center) {
      try {
        coordinates = JSON.parse(center)?.coordinates;
      } catch (error) {
        console.error('Object search card coordinates parse error', error);
      }
    }

    return {
      type: 'FeatureCollection',
      buildingId: generateGUID(),
      title: OBJECT_CARD_TITLE.SEARCH_RESULT_CARD,
      icon: 'objectCardSearch',
      useOldApiBuildingOption: false,
      features: {
        type: 'ObjectFeature',
        geometry: { type: 'Point', coordinates },
        properties: {
          name: { value: name ?? '', isEnabled: true },
          address: { value: address ?? '', isEnabled: true },
          FNO_code: { value: '', isEnabled: false },
          FNO_name: { value: '', isEnabled: false },
          ZU_area: { value: 0, isEnabled: false },
          act_AGR: { value: '', isEnabled: false },
          cadNum: { value: '', isEnabled: false },
          designer: { value: '', isEnabled: false },
          developer: { value: '', isEnabled: false },
          h_abs: { value: 0, isEnabled: false },
          h_otn: { value: 0, isEnabled: false },
          h_relief: { value: 0, isEnabled: false },
          okrug: { value: '', isEnabled: false },
          rajon: { value: '', isEnabled: false },
          s_naz: { value: 0, isEnabled: false },
          s_obsh: { value: 0, isEnabled: false },
          s_podz: { value: 0, isEnabled: false },
          spp_gns: { value: 0, isEnabled: false },
        },
      },
    };
  }

  /**
   * Возвращает значение свойства по данному коду.
   *
   * @param {keyof ObjectInfo['features']['properties']} code - Код свойства.
   * @return {string} - Значение свойства. Возвращает пустую строку, если #objectInfoFeaturesProperties равен null.
   */
  getPropertyValueByCode(code: keyof ObjectInfo['features']['properties']): number | string {
    if (this.#objectInfoFeaturesProperties === null) {
      return '';
    }

    const prop = this.#objectInfoFeaturesProperties[code];

    return prop.value ?? '';
  }

  /**
   * Закрывает карточку объекта в соответствии с режимом управления.
   *
   * @param {ControlMode} controlMode - Режим управления.
   * @return {void}
   */
  closeObjectCardWhenAvatarMode(controlMode: ControlMode): void {
    if (controlMode.mode === 'character' || controlMode.mode === 'vehicle') {
      this.clearData();
    }
  }

  /**
   * Проверяет, открыта ли в настоящее время карта объекта поиска.
   *
   * @return {boolean} Возвращает true, если карта объекта поиска открыта, в противном случае - false.
   */
  isSearchObjectCardOpen(): boolean {
    return this.additionData().icon === 'objectCardSearch';
  }

  /**
   * Добавляет свойство со своим значением в объект сгруппированных свойств.
   *
   * @param {GroupedProperties} acc - Объект сгруппированных свойств, к которому будет добавлено свойство со значением.
   * @param {Omit<Property, 'value'>} item - Объект свойства с опущенным свойством значения.
   * @param {string} value - Значение свойства.
   * @returns {GroupedProperties} - Обновленный объект сгруппированных свойств.
   */
  private pushProp(acc: GroupedProperties, item: Omit<Property, 'value'>, value: number | string): GroupedProperties {
    if (acc[item.group.type]) {
      acc[item.group.type].values.push({ ...item, value });
    } else {
      acc[item.group.type] = {
        type: item.group.type,
        collapsed: !this.#collapsedGroupsType.includes(item.group.type),
        values: [{ ...item, value }],
      };
    }

    return acc;
  }

  /**
   * Извлекает типы свёрнутых групп на основе значения, хранящегося в localStorage.
   *
   * @returns {PropertyGroup['type'][]} Массив типов PropertyGroup, представляющий свёрнутые группы.
   */
  private getCollapsedGroupType(): PropertyGroup['type'][] {
    const type = this.window.localStorage.getItem(OBJECT_CARD_VIEW_TYPE);

    if (type === null) {
      return [PropertyGroup.GENERAL.type];
    }

    if (type === `${View.MINIMUM}`) {
      return [];
    }

    if (type === `${View.REVEALED}`) {
      return PropertyGroupKeysOrder as unknown as PropertyGroup['type'][];
    }

    return [PropertyGroup.GENERAL.type];
  }

  /**
   * Извлекает тип просмотра карточки объекта из localStorage.
   *
   * @private
   * @returns {number} Тип просмотра карточки объекта.
   */
  private getObjectCardView(): number {
    const type = this.window.localStorage.getItem(OBJECT_CARD_VIEW_TYPE);

    if (type === null) {
      return View.STANDARD;
    }

    if (type === `${View.MINIMUM}` || type === `${View.REVEALED}`) {
      return +type;
    }

    return View.STANDARD;
  }
}
