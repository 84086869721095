import { DestroyRef, Directive, OnInit, TemplateRef, ViewContainerRef, effect } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';

import { HideInterfaceService } from '../../services/hide-interface.service';
import { RouterIsLoadedService } from '../../services/router-is-loaded.service';

/**
 * Директива для скрытия интерфейса на основании наличия параметра запроса.
 *
 * @Directive
 * @selector '[appHideInterface]'
 * @standalone true
 */
@Directive({
  selector: '[appHideInterface]',
  standalone: true,
})
export class HideInterfaceDirective implements OnInit {
  /**
   * Переключает эффект скрытия.
   *
   * @function toggleHiddenEffect
   * @description Эта функция переключает эффект скрытия, основываясь на текущем состоянии видимости интерфейса.
   */
  readonly toggleHiddenEffect = effect(() => {
    const isHidden = !this.hideInterfaceService.isVisible();
    this.checkAndToggleView(isHidden);
  });

  /**
   * Метод конструктора для инициализации класса.
   *
   * @param {ViewContainerRef} viewContainerRef - Ссылка на контейнер представления.
   * @param {TemplateRef<void>} templateRef - Ссылка на шаблон.
   * @param {DestroyRef} destroyRef - Ссылка на компонент уничтожения.
   * @param {ActivatedRoute} activatedRoute - Ссылка на активированный маршрут.
   * @param {HideInterfaceService} hideInterfaceService - Сервис для скрытия интерфейсов.
   * @param {RouterIsLoadedService} routerIsLoadedService - Служба для определения, загружен ли маршрутизатор.
   */
  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<void>,
    private destroyRef: DestroyRef,
    private activatedRoute: ActivatedRoute,
    private hideInterfaceService: HideInterfaceService,
    private routerIsLoadedService: RouterIsLoadedService,
  ) {}

  /**
   * Инициализирует компонент.
   *
   * @returns {void}
   */
  ngOnInit(): void {
    this.activatedRoute.queryParamMap.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((map) => {
      this.hideInterfaceService.setVisibility(!map.has('hideInterface'));
      this.routerIsLoadedService.setRouterIsLoaded(true);
    });
  }

  /**
   * Проверяет и переключает вид в зависимости от параметра hide.
   *
   * @param {boolean} hide - Определяет, скрыть вид или нет.
   *
   * @return {void}
   */
  private checkAndToggleView(hide: boolean): void {
    if (hide) {
      this.viewContainerRef.clear();
    } else {
      if (this.viewContainerRef.length === 0) {
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      }
    }
  }
}
