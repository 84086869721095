export const GeneratePhases = {
  OFF: 'off',
  INITIAL: 'initial',
  AREA_SELECTION: 'areaSelection',
  AREA_CREATED: 'areaCreated',
  ENTRY_POINT_SELECTION: 'entryPointSelection',
  GENERATION_PARAMS: 'generationParams',
  GENERATION_IN_PROGRESS: 'generationInProgress',
  GENERATION_COMPLETE: 'generationComplete',
  DATA_INFO_SELECTION: 'dataInfoSelection',
} as const;

export type GeneratePhaseType = (typeof GeneratePhases)[keyof typeof GeneratePhases];
