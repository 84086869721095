import { HttpClient } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { Observable, catchError, map, tap } from 'rxjs';

import { UserSettings } from '../../types/user-settings.interface';
import { DEFAULT_SETTINGS, USER_SETTINGS } from './user-settings.constant';
import { UserSettingsProvider } from './user-settings.interface';

/**
 * Класс, предоставляющий API для работы с настройками пользователя.
 * @implements {UserSettingsProvider}
 */
@Injectable({ providedIn: 'root' })
export class UserSettingsApiService implements UserSettingsProvider {
  /**
   * Сигнал, содержащий настройки пользователя.
   * @type {Signal<UserSettings>}
   */
  settings = signal<UserSettings>(DEFAULT_SETTINGS);

  /**
   * Внедряет зависимость HttpClient в переменную httpClient.
   * @param {HttpClient} inject - Объект HttpClient для внедрения
   * @type {HttpClient}
   */
  readonly #httpClient = inject(HttpClient);

  /**
   * Получает настройки пользователя.
   * @returns {Observable<UserSettings>} - Наблюдаемый объект с настройками пользователя
   */
  getSettings(): Observable<UserSettings> {
    return this.#httpClient.get<UserSettings>(USER_SETTINGS).pipe(
      tap((userSettings) => this.settings.set(userSettings)),
      catchError(() => this.patchSettings(DEFAULT_SETTINGS)),
    );
  }

  /**
   * Обновляет настройки пользователя.
   * @param {Partial<UserSettings>} settings - Частичные настройки пользователя для обновления
   * @returns {Observable<UserSettings>} - Наблюдаемый объект с обновленными настройками пользователя
   */
  patchSettings(settings: Partial<UserSettings>): Observable<UserSettings> {
    const userSettings = { ...this.settings(), ...settings };

    return this.#httpClient.post<UserSettings>('/auth/user-settings', userSettings).pipe(map(() => userSettings));
  }
}
